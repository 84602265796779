@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@media print {
    body,
    *,
    html {
        visibility: hidden;
    }
    .ps {
        overflow: scroll !important;
        overflow-anchor: none;
        -ms-overflow-style: none;
        touch-action: auto;
        -ms-touch-action: auto;
    }
}
img {
    width: 100%;
}

* {
    font-family: 'Poppins', sans-serif;
}

.text-poppins {
    font-family: 'Poppins', sans-serif;
}

.text-black {
    color: #000 !important;
}
.font-weight-bold {
    font-weight: 500 !important;
}
._img-profile {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    object-fit: cover;
}
.text-elipse {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.radius-5 {
    border-radius: 5px;
}
.object-fit {
    object-fit: cover;
}
.show-html-organized p {
    margin: 0px;
}

.swal2-styled.swal2-confirm {
    box-shadow: none !important;
}
.bg-login {
    background: url('./assets/bg_pattern.png');
}

.container-chart {
    height: 250px;
}

@media screen and (min-width: 768px) {
    .container-chart {
        height: 300px;
    }
}
